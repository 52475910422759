<template>
	<b-container fluid>
		<bo-page-title />
		<section id="home">
			<b-tabs>
				<b-tab title="Page Settings">
					<HeroSection :setting.sync="heroSetting" />
					<StrukturSection :data.sync="strukturData" />
					<PedomanSection :data.sync="pedomanData" />
					<DokumenLainnya :data.sync="docLainnya" />
				</b-tab>
				<b-tab title="SEO Settings">
					<SeoSection :setting.sync="seoPageSetting" />
				</b-tab>
			</b-tabs>
		</section>
	</b-container>
</template>

<script>
import Gen from '@/libs/Gen'
import GlobalVue from '@/libs/Global.vue'

import HeroSection from './HeroSection.vue'
import SeoSection from './SeoSection.vue'
import StrukturSection from './StrukturSection.vue'
import PedomanSection from './PedomanSection.vue'
import DokumenLainnya from './DokumenLainnya.vue'
const _ = global._

export default {
  extends: GlobalVue,
  components: {
    HeroSection,
    SeoSection,
		StrukturSection,
		PedomanSection,
		DokumenLainnya,
  },
  data() {
    return {
      heroSetting:{},
      seoPageSetting:{},
			strukturData:{data:false},
			pedomanData:{data:false},
			docLainnya:{data:false},
    }
  },
  mounted(){
    this.getAllData()
  },
  computed: {
  },
  methods: {
    getAllData(){
      this.loadingOverlay=true
      this.restAPI('page-setting')
      this.restAPI('struktur-data')
      this.restAPI('pedoman-data')
      this.restAPI('doc-lainnya-data')
    },
    restAPI(slug, params=false, callback=false){
      Gen.apiRest(
        "/get/"+this.modulePage+"/"+slug,
        {
          params:params||{}
        }
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        this.loadingOverlay=false
        if(callback) callback()
      })
    },
  },
  watch:{
    $route(){
      this.getAllData()
    }
  }
}
</script>
